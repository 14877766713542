import { TextField as MaterialTextField } from '@rmwc/textfield'
import '@rmwc/textfield/styles'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { styled, theme } from 'twin.macro'

const StyledMaterialTextField = styled(MaterialTextField)`
  ${tw`w-full rounded-none bg-background-grey!`}
  .mdc-floating-label {
    ${tw`font-light pb-1`}
  }
  &:not(.mdc-text-field--disabled) {
    .mdc-floating-label {
      ${tw`text-primary-500`}
      &.mdc-floating-label--float-above {
        ${tw`text-primary-500`}
      }
    }
    &.mdc-text-field--invalid {
      .mdc-text-field__input {
        ${tw`text-red-500!`}
        caret-color: ${theme`colors.red.500`};
      }
      .mdc-floating-label {
        ${tw`text-red-500!`}
      }
    }
  }
  .mdc-text-field__input {
    ${tw`font-light text-primary-500 pb-0.5`}
    caret-color: ${theme`colors.secondary.500`};
  }
  .mdc-line-ripple {
    ${tw`hidden`}
  }
`
const FormTextField = ({ ...props }) => {
  return <StyledMaterialTextField {...props} />
}

FormTextField.propTypes = {
  invalid: PropTypes.bool,
  message: PropTypes.string,
  props: PropTypes.object,
}

export default FormTextField
