import { HelmetDatoCms } from 'gatsby-source-datocms'
import PropTypes from 'prop-types'
import React from 'react'
import { usePageContext } from '../context/page-context'

function SEO({ pageTags, faviconTags, siteUrl, noIndex }) {
  const { originalPath } = usePageContext()

  const favicon = {
    tags: [
      ...faviconTags.filter((t) => t.attributes.name !== 'application-name'),
      { attributes: { name: 'application-name', content: 'bolt.inc' }, tagName: 'meta' },
    ],
  }

  return (
    <HelmetDatoCms seo={pageTags} favicon={favicon}>
      <html lang="en" />
      {originalPath === "/" ? <link rel="canonical" href={`${siteUrl}${originalPath}`} />
      : <link rel="canonical" href={`${siteUrl}${originalPath}/`} /> }
      {noIndex && <meta name="robots" content="noindex" />}
    </HelmetDatoCms>
  )
}

SEO.propTypes = {
  pageTags: PropTypes.object,
  faviconTags: PropTypes.arrayOf(PropTypes.object),
  siteUrl: PropTypes.string,
  noIndex: PropTypes.bool,
}

export default SEO
