import Lottie from 'lottie-web'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import tw, { css, theme } from 'twin.macro'
import logoCyanAccent from '../images/logo-json/logo-coloured-with-cyan.json'
import logoWhiteAccent from '../images/logo-json/logo-coloured-with-white.json'
import logoWhite from '../images/logo-json/logo-reverse-white.json'
import { global } from '../styles/global'
import { max } from '../utils/breakpoints'
import { LinkType } from '../utils/prop-types'
import Button from './button'
import Link from './link'
import MainNav from './main-nav'

const Header = ({
  callToAction,
  primaryLinks,
  secondaryLinks,
  socialMedia,
  copyright,
  headerTransition,
  headerTransitionColor,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [detectScroll, setDetectScroll] = useState(false)
  const [detectHover, setDetectHover] = useState(false)
  const logoDiv = useRef()

  const handleHover = (hover) => setDetectHover(hover)

  const handleScroll = () => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const loadAnimation = (json) => {
    return Lottie.loadAnimation({
      container: logoDiv.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: json,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMax meet',
      },
    })
  }

  useEffect(() => {
    const displayWhiteLogo =
      !detectScroll &&
      !detectHover &&
      headerTransition &&
      headerTransitionColor === 'white' &&
      !isOpened

    const isMobile =
      parseFloat(document.documentElement.style.getPropertyValue('--vw').slice(0, -2)) < 10.24

    const selectedLogo = (() => {
      if (displayWhiteLogo) return logoWhite
      if (isMobile && isOpened) return logoWhiteAccent
      return logoCyanAccent
    })()

    const animation = loadAnimation(selectedLogo)
    return () => {
      animation.destroy()
    }
  }, [detectScroll, detectHover, headerTransition, headerTransitionColor, isOpened])

  return (
    <header
      css={[
        css`
          ${tw`transition-all duration-300 ease-in-out`}
        `,
        css`
          ${max.lg} {
            background: linear-gradient(
              to left,
              ${theme`colors.secondary.500`} 50%,
              ${headerTransition && !detectScroll && !detectHover ? 'transparent 50%' : 'white 50%'}
            );
            background-size: 200% 100%;
            background-position: left bottom;
          }
          background: ${headerTransition && !detectScroll && !detectHover
            ? 'transparent'
            : 'white'};
        `,
        css`
          ${tw`fixed inset-x-0 top-0 z-20 h-header-mobile lg:h-header-default`}
        `,
        isOpened &&
          css`
            ${max.lg} {
              ${tw`bg-right-bottom`}
            }
          `,
      ]}
    >
      <div css={[global`layout.container`, global`layout.grid`, tw`items-center h-full`]}>
        <Link to="/" style={tw`col-span-1`} onClick={() => setIsOpened(false)}>
          <div ref={logoDiv} css={tw`h-logo-mobile lg:h-logo-default`} />
        </Link>
        <div
          css={tw`col-start-4 col-end-5 md:(col-start-8 col-end-9) lg:(col-start-3 col-end-12 h-full px-4) xl:(col-start-4 col-end-13 px-0)`}
        >
          <MainNav
            primaryLinks={primaryLinks}
            secondaryLinks={secondaryLinks}
            socialMedia={socialMedia}
            copyright={copyright}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            handleHover={handleHover}
            headerTransition={headerTransition}
            headerTransitionColor={headerTransitionColor}
          />
        </div>

        <div
          css={[
            tw`hidden lg:(flex justify-end col-span-3 col-start-12 col-end-13) xl:(col-start-13 col-end-17)`,
          ]}
        >
          <Button
            type="primary"
            size="xs"
            label={callToAction.label}
            link={callToAction.link}
            theme="navy-cyan"
            style={tw`hidden whitespace-nowrap lg:block`}
            hideCaret
          />
        </div>
      </div>
      <div
        css={[
          tw`hidden lg:(absolute inset-x-0 bottom-0 block h-px -z-10)`,
          isOpened && tw`bg-secondary-500`,
          detectHover && tw`bg-line-grey`,
          detectScroll && tw`bg-line-grey`,
        ]}
      />
    </header>
  )
}

Header.propTypes = {
  callToAction: PropTypes.object,
  primaryLinks: PropTypes.arrayOf(LinkType),
  secondaryLinks: PropTypes.arrayOf(LinkType),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
}

export default Header
