import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import ReactSelect, { components } from 'react-select'
import tw, { css, theme } from 'twin.macro'
import { ReactComponent as Indicator } from '../images/icon-caret-down-lg.svg'
import { lg } from '../utils/breakpoints'
import { StyleType } from '../utils/prop-types'
import Link from './link'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Indicator css={tw`fill-primary`} />
  </components.DropdownIndicator>
)

const Option = ({ children, data, ...props }) => {
  return data.isLink ? (
    <Link to={data.value}>
      <components.Option {...props}>{children}</components.Option>
    </Link>
  ) : (
    <components.Option {...props}>{children}</components.Option>
  )
}

Option.propTypes = {
  children: PropTypes.any,
  data: PropTypes.shape({
    value: PropTypes.any,
    isLink: PropTypes.bool,
  }),
}

const menuStyle = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  margin-top: 0;
  animation: fadeIn 0.2s ease-in-out;
`

const Menu = (props) => {
  const { children } = props

  return (
    <components.Menu {...props} css={menuStyle}>
      {children}
    </components.Menu>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}

const defaultStyle = (backgroundColor, detectScroll, hasError) => {
  const errorStyle = {
    control: {
      backgroundColor: theme`colors.red.500`,
    },
  }

  const disabledStyle = {
    control: {
      backgroundColor: theme`colors.smoke-grey`,
    },
  }

  return {
    control: (provided, { isDisabled }) => {
      return {
        ...provided,
        padding: '0.75rem 1.25rem',
        border: 'none',
        borderRadius: '0px',
        color: theme`colors.primary.500`,
        backgroundColor: backgroundColor || theme`colors.primary.50`,
        ...(hasError && errorStyle.control),
        ...(isDisabled && disabledStyle.control),
        boxShadow: 'none',
        minWidth: '8rem',
        [lg]: {
          ...provided[lg],
          padding: '1rem 1.5rem',
          minWidth: '14.25rem',
        },
      }
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease-in-out',
      transform: state.selectProps.menuIsOpen && 'scaleY(-1)',
      padding: '0',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      lineHeight: '2',
      fontWeight: '500',
      width: '100%',
      [lg]: {
        ...provided[lg],
        fontSize: '1rem',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, { isDisabled }) => ({
      ...provided,
      display: isDisabled ? 'none' : 'flex',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0px',
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      transition: 'all 0.3s ease-in-out',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme`colors.primary.500`,
      width: '100%',
      opacity: '0.7',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0.75rem 1.25rem 0.75rem 1.5rem',
      [lg]: {
        ...provided[lg],
        padding: '1rem 1.5rem 1rem 2rem',
      },
      color: theme`colors.primary.500`,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      backgroundColor: theme`colors.background-grey-dark`,
      '&:hover': {
        backgroundColor: theme`colors.select-hover`,
      },
      border: 'none',
      borderRadius: '0px',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '0.75rem 1.25rem',
      [lg]: {
        ...provided[lg],
        padding: '1rem 1.5rem',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: theme`colors.primary.500`,
      padding: '0 0.25rem',
      [lg]: {
        ...provided[lg],
        padding: '0 0.5rem',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: theme`colors.primary.500`,
    }),
  }
}

const Select = ({ style, isSearchable, hasError, backgroundColor, selectStyle, ...props }) => {
  const [detectScroll, setDetectScroll] = useState(false)
  const handleScroll = () => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const selectedStyle = useMemo(
    () =>
      selectStyle
        ? selectStyle(backgroundColor, hasError)
        : defaultStyle(backgroundColor, detectScroll, hasError),
    [selectStyle, backgroundColor, detectScroll, hasError]
  )
  return (
    <ReactSelect
      isSearchable={isSearchable}
      components={{ DropdownIndicator, Option, Menu }}
      styles={selectedStyle}
      css={style}
      {...props}
    />
  )
}

Select.defaultProps = {
  isSearchable: false,
}

Select.propTypes = {
  style: StyleType,
  isSearchable: PropTypes.bool,
  hasError: PropTypes.bool,
  backgroundColor: PropTypes.string,
  selectStyle: PropTypes.func,
  props: PropTypes.object,
}

export default Select
