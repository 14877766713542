import PropTypes from 'prop-types'
import React from 'react'
import { theme } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Select from './select'

const selectStyle = (backgroundColor, hasError) => {
  const errorStyle = {
    placeholder: {
      color: theme`colors.red.500`,
    },
  }

  const disabledStyle = {
    control: {
      backgroundColor: theme`colors.smoke-grey`,
    },
  }

  return {
    control: (provided, { isDisabled }) => {
      return {
        ...provided,
        padding: '1rem',
        border: 'none',
        borderRadius: '0px',
        color: theme`colors.black`,
        backgroundColor: backgroundColor || theme`colors.white`,
        ...(isDisabled && disabledStyle.control),
        boxShadow: 'none',
        minWidth: '8rem',
      }
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease-in-out',
      transform: state.selectProps.menuIsOpen && 'scaleY(-1)',
      padding: '0',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '1rem',
      lineHeight: '1.5',
      fontWeight: '300',
      width: '100%',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided, { isDisabled }) => ({
      ...provided,
      display: isDisabled ? 'none' : 'flex',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0px',
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      transition: 'all 0.3s ease-in-out',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme`colors.black`,
      width: '100%',
      opacity: '1',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      margin: '0',
      ...(hasError && errorStyle.placeholder),
    }),
    option: (provided) => ({
      ...provided,
      padding: '1rem',
      color: theme`colors.black`,
      backgroundColor: theme`colors.background-grey`,
      '&:hover': {
        backgroundColor: theme`colors.select-hover`,
      },
      border: 'none',
      borderRadius: '0px',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      padding: '1rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: theme`colors.black`,
      padding: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: theme`colors.primary.500`,
    }),
  }
}

const FormSelect = ({ style, hasError, backgroundColor, ...props }) => {
  return (
    <Select
      style={style}
      hasError={hasError}
      backgroundColor={backgroundColor}
      selectStyle={selectStyle}
      {...props}
    />
  )
}

FormSelect.propTypes = {
  style: StyleType,
  hasError: PropTypes.bool,
  backgroundColor: PropTypes.string,
  props: PropTypes.object,
}

export default FormSelect
