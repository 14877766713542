import { graphql, useStaticQuery } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import tw from 'twin.macro'
import Global from '../styles/global'
import Footer from './footer'
import DemoForm from './demo-form'
import Header from './header'
import SEO from './seo'

gsap.registerPlugin(ScrollTrigger)

const useSiteComponents = () => {
  const { site, datoCmsSite, shared } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
        globalSeo {
          siteName
          titleSuffix
          twitterAccount
          facebookPageUrl
          fallbackSeo {
            title
            description
            image {
              url
            }
            twitterCard
          }
        }
      }
      shared: datoCmsShared {
        logo {
          url
          alt
          title
        }
        mainNavLinks {
          link
          subLinks {
            link
            label
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
            image {
              url
              alt
              title
            }
          }
          label
        }
        demo {
          link
          label
        }
        socialMediaLabel
        socialMedia {
          title
          image {
            url
            alt
            title
          }
          accountLink {
            link
            label
          }
        }
        footerLogo {
          url
          alt
          title
        }
        productsLabel
        productsLinks {
          link
          label
        }
        insightsLabel
        insightsLinks {
          link
          label
        }
        exploreLabel
        exploreLinks {
          link
          label
        }
        legalLabel
        legalLinks {
          link
          label
        }
        globalSiteLabel
        globalSiteLink {
          link
          label
        }
        copyright
        translations
      }
    }
  `)

  return { site, datoCmsSite, shared }
}

const setViewportWidth = () => {
  const vw = document.documentElement.clientWidth / 100
  document.documentElement.style.setProperty('--vw', `${vw}px`)
}

const Layout = ({
  pageTags,
  noIndex,
  location,
  headerTransition,
  headerTransitionColor,
  children,
}) => {
  const siteComponents = useSiteComponents()

  /* Handling states and callbacks for global demo form / modal */
  const [demoForm, setDemoForm] = useState(false)

  useEffect(() => {
    setDemoForm(location?.hash === '#request-demo')
  }, [location])

  const handleDemoClose = useCallback(() => {
    setDemoForm(false)
    if (typeof window !== `undefined`) {
      window.history.pushState({}, document.title, window.location.pathname)
    }
  }, [location])

  useEffect(() => {
    setViewportWidth()
    const onResize = debounce(setViewportWidth, 50)
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize)
    }
    return () => window.removeEventListener('resize', onResize)
  }, [setViewportWidth])

  const [initialized, setInitialized] = useState(false)

  useEffect(() => setInitialized(true), [])

  return (
    <div css={[tw`relative z-0 flex flex-col min-h-screen`]}>
      <Global />
      <SEO
        pageTags={pageTags}
        faviconTags={get(siteComponents, 'datoCmsSite.faviconMetaTags.tags')}
        siteUrl={get(siteComponents, 'site.siteMetadata.siteUrl')}
        noIndex={noIndex}
      />
      <Header
        logo={get(siteComponents, 'shared.logo')}
        callToAction={get(siteComponents, 'shared.demo')}
        primaryLinks={get(siteComponents, 'shared.mainNavLinks')}
        secondaryLinks={get(siteComponents, 'shared.legalLinks')}
        socialMedia={get(siteComponents, 'shared.socialMedia')}
        copyright={get(siteComponents, 'shared.copyright')}
        headerTransition={headerTransition}
        headerTransitionColor={headerTransitionColor}
      />
      <div
        css={[
          tw`flex-auto transition-opacity duration-300 bg-white pt-18 lg:pt-20`,
          !initialized && tw`opacity-0`,
        ]}
      >
        {children}
      </div>
      {initialized && (
        <Footer
          logo={get(siteComponents, 'shared.footerLogo')}
          links={compact([
            get(siteComponents, 'shared.productsLinks'),
            get(siteComponents, 'shared.insightsLinks'),
            get(siteComponents, 'shared.exploreLinks'),
          ])}
          linkHeaders={compact([
            get(siteComponents, 'shared.productsLabel'),
            get(siteComponents, 'shared.insightsLabel'),
            get(siteComponents, 'shared.exploreLabel'),
          ])}
          legalLabel={get(siteComponents, 'shared.legalLabel')}
          legal={get(siteComponents, 'shared.legalLinks')}
          socialMediaLabel={get(siteComponents, 'shared.socialMediaLabel')}
          socialMedia={get(siteComponents, 'shared.socialMedia')}
          globalSiteLabel={get(siteComponents, 'shared.globalSiteLabel')}
          globalSite={get(siteComponents, 'shared.globalSiteLink')}
          copyright={get(siteComponents, 'shared.copyright')}
        />
      )}
      <DemoForm isOpened={demoForm} handleClose={handleDemoClose} />
    </div>
  )
}

Layout.defaultProps = {
  noIndex: false,
  headerTransition: false,
  headerTransitionColor: 'navy',
}

Layout.propTypes = {
  pageTags: PropTypes.object,
  noIndex: PropTypes.bool,
  location: PropTypes.object,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
  children: PropTypes.node.isRequired,
}

export default Layout
